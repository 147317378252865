import { Component, OnInit, Input } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.page.html',
  styleUrls: ['./order-status.page.scss'],
})
export class OrderStatusPage implements OnInit {

  @Input() currentOrderDetail: any;
  value = 0.25;
  buffer = 0.5;
  status;
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController
  ) {
    this.currentOrderDetail = navParams.get('orderData');
    console.log(this.currentOrderDetail);
    switch (this.currentOrderDetail.status) {
      case 'WAITING':
        this.value = 0.5;
        this.buffer = 0.5;
        this.status = 'WAITING';
        break;
      case 'ACCEPTED':
        this.value = 0.75;
        this.buffer = 0.25;
        this.status = 'PROCESSING';
        break;
      case 'COMPLETED':
        this.value = 1;
        this.buffer = 0;
        this.status = 'COMPLETED';
        break;
    }
   }


  ngOnInit() {
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

}
