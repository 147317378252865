import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { USER_STATUS } from '../../app.enum';
import { ErrorHandler } from '../../errorhandler.service';
import { FormErrorService } from '../../form-error.service';
import { SAVED_WEB_MOBILE } from '../../shared/constants/app.constant';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../../storage.service';
import { UiService } from '../../ui.service';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-web-login',
  templateUrl: './web-login.page.html',
  styleUrls: ['./web-login.page.scss'],
})
export class WebLoginPage implements OnInit {
  showMobileSection = true;
  showPasscodeSection = false;
  mobileNumberFormControl = new FormControl(
    localStorage.getItem(SAVED_WEB_MOBILE) !== '9999999999'
      ? localStorage.getItem(SAVED_WEB_MOBILE)
      : null,
    [Validators.required, Validators.pattern(/^[6-9]\d{9}$/)]
  );
  mobileValidate$: Observable<any>;
  sendOtp$: Observable<any>;
  currentOtp;
  otpTimer = 0;
  otpFormControl = new FormControl(null, Validators.required);
  verifyUser$: Observable<any>;
  storageData: StorageInterface = {} as StorageInterface;
  skipLanding = true;
  fetchingDetails = false;

  constructor(
    private router: Router,
    private _formErrorService: FormErrorService,
    private _uiService: UiService,
    private _authService: AuthService,
    private _storageService: StorageService,
    private _activatedRoute: ActivatedRoute,
    private _http: HttpClient,
    private _errorHandler: ErrorHandler,
    private titleService: Title,
    private metaTagService: Meta
  ) {
    // console.log('current title:::::' + this.titleService.getTitle());
  }

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this._activatedRoute.params.subscribe((param) => {
        if (param?.id) {
          let data: {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          } = {} as {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          };

          data.vendorCode = param.id.split('D')[0];
          data.diningAreaId = param.id.split('D')[1].split('T')[0];
          data.tableNumber = param.id.split('D')[1].split('T')[1];

          this.setVendorDetails(data);
          this.fetchingDetails = true;
        }
      });
    });
  }

  setVendorDetails(data: {
    vendorCode: string;
    tableNumber: string;
    diningAreaId: string;
  }) {
    this._http
      .get(environment.base_url + environment.vendor_fetch, {
        params: {
          vendorCode: 'EI' + data.vendorCode,
          tableNumber: data.tableNumber,
          diningAreaId: data.diningAreaId,
        },
      })
      .pipe(catchError(this._errorHandler.handleError))
      .subscribe(
        async (responseData: any) => {
          if (responseData.statusCode === 200) {
            this.storageData.VendorKey = responseData.data.VendorIdPK;
            this.storageData.VendorStatus = responseData.status;
            this.storageData.VendorStoreName =
              responseData.data.vendorStoreName;
            this.storageData.VendorStoreAddress =
              responseData.data.vendorLocation;
            this.storageData.CurrentTablePK = responseData.TableIdPK;
            this.storageData.CurrentTableNumber = data.tableNumber;
            this.storageData.VendorStoreKey = 'EI' + data.vendorCode;
            this.storageData.VendorUpiQR = responseData.data.vendorQrCode;
            this.storageData.DiningAreaId = data.diningAreaId;
            this.storageData.VendorCityIdKey = responseData.data.CityIdFK;

            await this._storageService.setObject(
              StorageEnum.UserData,
              this.storageData
            );

            if (localStorage.getItem(SAVED_WEB_MOBILE)) {
              this.verifyMobile();
            } else {
              this.mobileNumberFormControl.patchValue('9999999999');
              console.log('here');

              this.verifyMobile();

              this.fetchingDetails = false;
            }
            this.skipLanding = true;
          }
          console.log(responseData);
          // TODO: Test on live deployment
          // this.titleService.setTitle(responseData.data.vendorStoreName);
          // this.metaTagService.addTags([
          //   {
          //     name: 'description',
          //     content: 'Welcome to Next Level Dining',
          //   },
          //   {
          //     name: 'keywords',
          //     content:
          //       'Best Restaurant, Online Food Ordering, Online Menu, Delicious Food',
          //   },
          // ]);
        },
        (error) => {
          this.fetchingDetails = false;

          console.log(error);
          // this.isContinueActive = false;
          // this.loadingMessage = false;
          if (error.status === 404) {
            // this.scannerError = false;
            this._uiService.presentToast('Invalid Code');
          } else {
            this._uiService.presentToast(error);
          }
        }
      );
  }

  verifyMobile() {
    this.mobileNumberFormControl.markAsTouched();
    if (!this.mobileNumberFormControl.valid) {
      this._uiService.presentToast('Please enter valid mobile number');
      return;
    }

    this.mobileValidate$ = this._authService.validateCustomer(
      this.mobileNumberFormControl.value,
      this._storageService.getFcmToken()
    );

    this.mobileValidate$.subscribe({
      next: async (responseData) => {
        if (responseData.body.statusCode === 200) {
          this._storageService.clearCartFromStorage();
          this.storageData.UserStatusKey = responseData.body.data.status;
          this.storageData.UserMobileKey = responseData.body.data.mobile;
          this.storageData.CustomerKey = responseData.body.data.CustomerIdPK;
          this.storageData.TokenKey = responseData.body.token;

          await this._storageService.setObject(
            StorageEnum.UserData,
            this.storageData
          );

          // for testing
          if (this.mobileNumberFormControl.value === '7777777777') {
            this.storageData.UserStatusKey = USER_STATUS.VERIFIED;
            await this._storageService.setObject(
              StorageEnum.UserData,
              this.storageData
            );
            this.router.navigate(['/landing']);
            return;
          }

          // stop otp for a while
          this.checkPassode(true);
          this.saveNumberInBrowser(this.mobileNumberFormControl.value);

          return;

          // for skip otp

          if (this.mobileNumberFormControl.value === '9999999999') {
            this.storageData.UserStatusKey = USER_STATUS.VERIFIED;
            await this._storageService.setObject(
              StorageEnum.UserData,
              this.storageData
            );
            this.router.navigate(['/landing']);
            return;
          }

          if (this.storageData.UserStatusKey === USER_STATUS.VERIFIED) {
            if (this.skipLanding) {
              this.router.navigate(['/store']);
            } else {
              this.router.navigate(['/landing']);
            }
            // this for web users to fetch last enterd mobile number
            return;
          }

          return;
          this.showMobileSection = false;
          this.showPasscodeSection = true;
          this.fetchingDetails = false;

          this.sendOtp();
        }
      },
      error: (err) => {
        this.fetchingDetails = false;
        this._uiService.presentToast(err);
      },
    });
  }

  sendOtp() {
    this.sendOtp$ = this._authService.sendOtp(
      this.mobileNumberFormControl.value
    );

    this.sendOtp$.subscribe({
      next: (responseData) => {
        if (responseData.body.statusCode === 200) {
          this.currentOtp = responseData.body.otp;
          this.startOtpTimer();
        } else {
          this._uiService.presentToast('Something went wrong. Try Again!');
        }
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  processSMS(res) {
    console.log(res);
  }

  startOtpTimer() {
    this.otpTimer = 60;
    const timerInterval = setInterval(() => {
      this.otpTimer = this.otpTimer - 1;
      if (this.otpTimer === 0) {
        clearInterval(timerInterval);
        return;
      }
    }, 1000);
  }

  checkPassode(skipOtp = false) {
    if (!this.otpFormControl.valid && !skipOtp) {
      this._uiService.presentToast('Please enter OTP to continue');
      return;
    }

    if (+this.otpFormControl.value === +this.currentOtp || skipOtp) {
      this.verifyUser$ = this._authService.updateUserStatus(
        USER_STATUS.VERIFIED,
        this.storageData.CustomerKey
      );

      this.verifyUser$.subscribe({
        next: async (res) => {
          this.storageData.UserStatusKey = USER_STATUS.VERIFIED;
          await this._storageService.setObject(
            StorageEnum.UserData,
            this.storageData
          );
          if (this.skipLanding) {
            this.router.navigate(['/store']);
          } else {
            this.router.navigate(['/landing']);
          }
          // this for web users to fetch last enterd mobile number
          this.saveNumberInBrowser(this.mobileNumberFormControl.value);
        },
        error: (err) => {
          console.log('auth-comp', err);
          this._uiService.presentToast(err);
        },
      });
    } else {
      this._uiService.presentToast('OTP is invalid');
      return;
    }
  }

  resetMobile() {
    this.showMobileSection = true;
    this.showPasscodeSection = false;
  }

  public getErrorMessage(fieldName: string, error: string): string {
    return this._formErrorService.getErrorMessage(fieldName, error);
  }

  saveNumberInBrowser(mobile: string) {
    localStorage.setItem(SAVED_WEB_MOBILE, mobile);
  }
}
