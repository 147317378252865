import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment.prod';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BannerFetchApiResponse } from '../model/banner.model';
import { ErrorHandler } from 'src/app/errorhandler.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  constructor(private http: HttpClient, private errorHandler: ErrorHandler) {}

  fetchBanner(data?: any): Observable<BannerFetchApiResponse> {
    const params = new HttpParams({ fromObject: data });
    return this.http
      .get<BannerFetchApiResponse>(
        environment.base_url + environment.banner_api,
        {
          params,
        }
      )
      .pipe(
        map((_) => {
          _.data.forEach((__) => {
            __.media = environment.base_url + __.media;
          });
          return _;
        }),
        catchError(this.errorHandler.handleError)
      );
  }
}
