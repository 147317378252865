import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from 'rxjs';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { UiService } from '../ui.service';
import { ProfileService } from './profile.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {
  updateFormGroup: FormGroup;
  storageData: StorageInterface;
  updateProfile$: Observable<any>;

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private profileService: ProfileService,
    private uiService: UiService
  ) {}

  ngOnInit() {
    this.setStorageData();
    this.buildForm();
  }

  setStorageData() {
    this.storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
    });
  }

  buildForm() {
    this.updateFormGroup = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
    });
  }

  onUpdateProfile() {
    if (!this.updateFormGroup.valid) {
      return;
    }
    this.updateProfile$ = this.profileService.updateCustomerProfile(
      this.updateFormGroup.value,
      this.storageData.CustomerKey
    );
    this.updateProfile$.subscribe((res) => {
      console.log(res);
      this.uiService.presentToast('Profile updated successfully');
    });
  }
}
