import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, throwError, Subject, from } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EventEmitter, Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from './storage.service';

@Injectable({ providedIn: 'root' })
export class AuthInterceptorService implements HttpInterceptor {
  public loadingOutput = new EventEmitter<any>();
  // public loadingSubject = new Subject<any>();
  isLoading;
  constructor(
    private router: Router,
    private loadingController: LoadingController,
    private storageService: StorageService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loadingOutput.emit(true);
    let promise = this.storageService.getObject(StorageEnum.UserData);

    return from(promise).pipe(
      mergeMap((data: StorageInterface) => {
        let clonedReq = this.addToken(req, data);
        return next.handle(clonedReq).pipe(
          map((event: HttpEvent<any>) => {
            this.dismiss();
            if (event instanceof HttpResponse) {
            }
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            // alert('error');
            // this.dismiss();
            if (error.status === 401) {
              this.router.navigate(['auth']);
            } else if (error.status === 0) {
              // alert('Internet connection error');
            }
            return throwError(error);
          })
        );
      })
    );
  }

  async presentLoading() {
    await this.dismiss();
    await this.loadingController
      .create({
        message: 'Loading',
      })
      .then((res) => {
        res.present();
      });
  }

  async dismiss() {
    while ((await this.loadingController.getTop()) !== undefined) {
      await this.loadingController.dismiss();
    }
  }

  private addToken(request: HttpRequest<any>, token: any) {
    if (token) {
      let clone: HttpRequest<any>;
      clone = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token.TokenKey}`,
        },
      });
      return clone;
    }

    return request;
  }
}
