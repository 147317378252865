import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from '../storage.service';
import { Observable, Subject } from 'rxjs';
import { map, retry } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OrderService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  fetchOrders(data, customerKey) {
    return this.http
      .get(environment.base_url + 'mobile/' + customerKey + '/fetchorder', {
        params: data,
      })
      .pipe(retry(1));
  }

  getReceipt(data, orderId, customerId) {
    return this.http.get(
      environment.base_url + 'mobile/orders/' + orderId + '/' + customerId,
      {
        params: data,
      }
    );
  }

  fetchInvoice(orderIdPK): Observable<any> {
    return this.http
      .get(environment.base_url + 'mobile/order/' + orderIdPK + '/invoice')
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }
}
