import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrderStatusPage } from '../order-status/order-status.page';
import { Observable } from 'rxjs';
import { OrderService } from '../order.service';
import { CartService } from 'src/app/cart.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from 'src/app/storage.service';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-active',
  templateUrl: './active.page.html',
  styleUrls: ['./active.page.scss'],
})
export class ActivePage implements OnInit {
  dataReturned: any;

  constructor(
    public modalController: ModalController,
    private orderService: OrderService,
    private cartService: CartService,
    private storageService: StorageService
  ) {}

  modal;
  cartCount;

  @Input() storeName = '';
  @Input() reload: Observable<any>;
  @Input() orders$: Observable<any[]>;
  @Input() emptyOrderStatus: boolean;

  ngOnInit() {
    this.reload.subscribe((res) => {
      console.log('reload', res);
    });
  }

  updateCartCountBadge() {
    this.cartCount = this.cartService.getFullCartData().length;
    if (this.cartCount === 0) {
      this.cartCount = undefined;
    }
  }

  async presentStatusModal(order: any) {
    // order.status = 'ACCEPTED';
    this.modal = await this.modalController.create({
      component: OrderStatusPage,
      componentProps: {
        orderData: order,
      },
      cssClass: 'order-status-modal',
    });

    // await this.orderService.statusModalEvent.next(order);
    this.modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        // alert('Modal Sent Data :'+ dataReturned);
      }
    });
    return await this.modal.present();
  }

  public trackItem(index: number, item: any) {
    return item ? item.status : undefined;
  }

  callRestaurant(order) {
    window.open('tel:' + order.vendor.alternateMobile);
  }
}
