import { Component, OnInit, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CreateAddressComponent } from '../create-address/create-address.component';
import { UserAddresses, UserAddressesService } from './user-addresses.service';
import { UiService } from '../ui.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';

@Component({
  selector: 'app-user-addresses',
  templateUrl: './user-addresses.page.html',
  styleUrls: ['./user-addresses.page.scss'],
  providers: [UserAddressesService],
})
export class UserAddressesPage implements OnInit {
  _userAddress = inject(UserAddressesService);
  _uiService = inject(UiService);
  _storageService = inject(StorageService);
  storageData: StorageInterface;
  addresses: UserAddresses[] = [] as UserAddresses[];

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.fetchAddresses();
    });
  }

  fetchAddresses() {
    this._userAddress.fetchAddresses().subscribe({
      next: (res) => {
        this.addresses = res.data;
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  async openAddressModal(isEdit = false, data?: UserAddresses) {
    const modal = await this.modalCtrl.create({
      component: CreateAddressComponent,
      initialBreakpoint: 0.5,
      breakpoints: [0.5, 0.75, 0.9],
      componentProps: {
        ...(isEdit && { address: data }),
      },
    });
    modal.present();

    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.fetchAddresses();
      }
    });
  }
}
