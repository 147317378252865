import { Component, Input, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { FormErrorService } from '../form-error.service';
import { FormService } from '../form.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { UiService } from '../ui.service';
import { SpinnerVisibilityService } from 'ng-http-loader';
import {
  UserAddresses,
  UserAddressesService,
} from '../user-addresses/user-addresses.service';

@Component({
  selector: 'app-create-address',
  templateUrl: './create-address.component.html',
  styleUrls: ['./create-address.component.scss'],
  providers: [UserAddressesService],
})
export class CreateAddressComponent implements OnInit {
  @Input() address: UserAddresses;
  addressForm!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _formErrorService = inject(FormErrorService);
  _formService = inject(FormService);
  _storageService = inject(StorageService);
  storageData: StorageInterface;
  _uiService = inject(UiService);
  _spinner = inject(SpinnerVisibilityService);
  _userAddressService = inject(UserAddressesService);

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    this.buildForm();
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.patchForm();
    });
  }

  buildForm() {
    this.addressForm = this._formBuilder.group({
      customerId: new FormControl(),
      cityId: new FormControl(),
      area: new FormControl(null, [Validators.required]),
      location: new FormControl(null, [Validators.required]),
      landmark: new FormControl(null),
      latitude: new FormControl(),
      longitude: new FormControl(),
    });

    if (this.address) {
      this.addressForm.patchValue(this.address);
    }
  }

  patchForm() {
    this.addressForm.patchValue({
      customerId: this.storageData.CustomerKey,
      cityId: this.storageData.VendorCityIdKey,
    });
  }

  onSaveAddress() {
    this._formService.markFormAsTouched(this.addressForm);
    if (!this.addressForm.valid) {
      this._uiService.presentAlert(
        'Error',
        '',
        'Please fil all the required fields!'
      );
      return;
    }

    if (!navigator.geolocation) {
      this._uiService.presentAlert(
        'Error',
        '',
        'Your device does not support GPS!'
      );
    } else {
      this._spinner.show();
      navigator.geolocation.getCurrentPosition(
        (res) => {
          const latitude = res.coords.latitude;
          const longitude = res.coords.longitude;
          this.addressForm.patchValue({
            latitude: latitude,
            longitude: longitude,
          });
          this._spinner.hide();
          this.onCreateAddress();
        },
        (error) => {
          this._spinner.hide();
          if (error.code === 1) {
            this._uiService.presentAlert(
              'Error',
              '',
              'We are unable to fetch location beacause you blocked the gps permission. Please allow gps by Tap To the left of the web address, Tap Lock and then Tap Permissions and then Location access'
            );
          }
          if (error.code === 2) {
            this._uiService.presentAlert(
              'Error',
              '',
              'Something went wrong while fetching your location, Please try again!'
            );
          }
          if (error.code === 3) {
            this._uiService.presentAlert(
              'Error',
              '',
              'Oops Timeout!, Please try again!'
            );
          }
        }
      );
    }
  }

  onCreateAddress() {
    if (this.address) {
      this._userAddressService
        .updateAddress(this.address.AddressIdPK, this.addressForm.value)
        .subscribe({
          next: (res) => {
            this.modalCtrl.dismiss(true);
            this._uiService.presentToast('Address Updated Successfully');
          },
          error: (err) => {
            this._uiService.presentToast(err);
          },
        });
      return;
    }
    this._userAddressService.createAddress(this.addressForm.value).subscribe({
      next: (res) => {
        this._uiService.presentToast('Address Created Successfully');
        this.modalCtrl.dismiss(true);
      },
      error: (err) => {
        this._uiService.presentToast(err);
      },
    });
  }

  onDismissModal() {
    this.modalCtrl.dismiss();
  }
}
