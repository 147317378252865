import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() currentMenu = '';
  @Input() isLoggedIn$: Subject<boolean> = new Subject<boolean>();
  isLoggedIn = true;
  constructor(private _router: Router) {}

  ngOnInit() {
    this.isLoggedIn$.subscribe((res) => {
      this.isLoggedIn = res;
    });
  }

  goToHome() {
    this._router.navigateByUrl('/store');
  }

  goToOrders() {
    this._router.navigateByUrl('/order');
  }

  goToAccount() {
    this._router.navigateByUrl('/user-account');
  }

  goToWebLogIn() {
    this._router.navigateByUrl('/auth/web-login');
  }
}
