import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-all-stores',
  templateUrl: './all-stores.page.html',
  styleUrls: ['./all-stores.page.scss'],
})
export class AllStoresPage implements OnInit {
  allStoresData = ['1', '2', '3', '4', '5', '6', '7', '8'];
  showSearchBar = false;

  constructor(
    private _router: Router,
    public modalController: ModalController
  ) {}

  ngOnInit() {}

  openStore() {
    this._router.navigateByUrl('/store');
  }

  toggleSearchBar(e) {
    this.showSearchBar = !this.showSearchBar;
  }

  async openScanner() {
    // const modal = await this.modalController.create({
    //   component: ScannerComponent,
    //   cssClass: "scanner-modal",
    // });
    // return await modal.present();
  }
}
