import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from '../order/order.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.page.html',
  styleUrls: ['./invoice.page.scss'],
})
export class InvoicePage implements OnInit {
  invoiceDetail: InvoiceInterface = {} as InvoiceInterface;
  fetchInvoice$: Observable<any>;
  orderId;

  constructor(
    private _orderService: OrderService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((param) => {
      console.log(param);
      this.orderId = param.id;
    });
    this.fetchInvoice();
  }

  fetchInvoice() {
    this.fetchInvoice$ = this._orderService.fetchInvoice(this.orderId);

    this.fetchInvoice$.subscribe((res) => {
      console.log(res);
      if (res) {
        this.invoiceDetail = res;
      }
    });
  }

  goToPlaystore() {
    window.open(
      'https://play.google.com/store/apps/details?id=com.easeinn.app'
    );
  }
}

export interface Customer {
  name?: any;
  mobile: string;
}

export interface Vendor {
  vendorStoreName: string;
  vendorLocation: string;
}

export interface Item {
  name: string;
}

export interface Orderdetail {
  quantity: number;
  price: string;
  addOnDetails?: any;
  item: Item;
}

export interface InvoiceInterface {
  orderPlacedId: string;
  orderPlacedTime: string;
  totalPrice: string;
  gstCharges?: any;
  deliveryCharges?: any;
  discount: number;
  paymentMode: string;
  customer: Customer;
  vendor: Vendor;
  orderdetails: Orderdetail[];
}
