import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { StoreService } from '../store/store.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  constructor(
    private _router: Router,
    private title: Title,
    private _storeService: StoreService,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.setTitleAndTags();

    return;
    if (window.location.href.split('.').length > 1) {
      // sub domain
      this.setTitleAndTags();
    } else {
      this._router.navigateByUrl('/welcome-screen');
    }
  }

  setTitleAndTags() {
    this._storeService.fetchVendorMetaTags().subscribe((res) => {
      console.log(res);
      this.title.setTitle(res.data[0].title);
      this.metaTagService.addTag({
        content: res.data[0].title,
        property: 'og:title',
        name: 'og_title',
      });
    });
  }
}
