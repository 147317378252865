import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment.prod';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.page.html',
  styleUrls: ['./user-account.page.scss'],
})
export class UserAccountPage implements OnInit {
  version = environment.appVersion;
  storageData: StorageInterface;
  mobileNumber;
  constructor(
    private alertController: AlertController,
    private storageService: StorageService,
    private router: Router,
    private _storageService: StorageService,
    private _uiService: UiService
  ) {}

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this._storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.mobileNumber = this.storageData.UserMobileKey;
    });
  }

  async presentLogoutAlert() {
    const alert = await this.alertController.create({
      header: 'Confirm Logout',
      subHeader: '',
      message: 'Are you sure you want to log out?',
      buttons: [
        {
          text: 'Yes, Logout',
          handler: () => {
            this.onLogOut();
          },
        },
        'No',
      ],
      mode: 'ios',
    });
    await alert.present();
  }

  onLogOut() {
    this.storageService.clearAllStorage().then(() => {
      this.router.navigate(['/auth'], { replaceUrl: true });
    });
  }

  navigateToProfile() {
    this._uiService.presentToast('Coming Soon!');
  }

  navigateToInstagram() {
    window.open('https://www.instagram.com/easeinn.app/', '_open');
  }
}
