import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ProfileService } from '../profile/profile.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.page.html',
  styleUrls: ['./delete-account.page.scss'],
  providers: [ProfileService],
})
export class DeleteAccountPage implements OnInit {
  deleteFG!: FormGroup;
  _formBuilder = inject(FormBuilder);
  _service = inject(ProfileService);
  _uiSevice = inject(UiService);

  constructor() {}

  ngOnInit() {
    this.deleteFG = this._formBuilder.group({
      reason: new FormControl(null, [Validators.required]),
      primaryMobile: new FormControl(null, [Validators.required]),
    });
  }

  onDelete() {
    if (!this.deleteFG.valid) {
      this._uiSevice.presentToast('Please give mobile and reason');
      return;
    }
    this._service.deleteAccountReq(this.deleteFG.value).subscribe({
      next: (_) => {
        this._uiSevice.presentAlert(
          '',
          '',
          'Request submitted successfully!',
          'ios',
          [{ text: 'OK' }]
        );
        this.deleteFG.reset();
      },
      error: (_) => {
        this._uiSevice.presentToast(_);
      },
    });
  }
}
