import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OrderService } from '../order.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from 'src/app/storage.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-past',
  templateUrl: './past.page.html',
  styleUrls: ['./past.page.scss'],
})
export class PastPage implements OnInit {
  fetchOrderObs: Observable<any>;
  getReceiptObs: Observable<any>;
  orders: any = [];
  emptyOrderStatus = false;
  storeName = '';
  storageData: StorageInterface;

  constructor(
    private orderService: OrderService,
    private storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this.storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.getInitialData();
    });
  }

  getInitialData() {
    this.storeName = this.storageData.VendorStoreName;
    this.fetchOrderObs = this.orderService.fetchOrders(
      {
        status: 'INACTIVE',
      },
      this.storageData.CustomerKey
    );

    this.fetchOrderObs.subscribe(
      (responseData) => {
        console.log(responseData.data.length);
        if (responseData.data.length === 0) {
          this.emptyOrderStatus = true;
        }
        this.orders = responseData.data;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getReceipt(orderDetail) {
    window.open(
      environment.bill_url +
        orderDetail.OrderIdPK +
        '/fetchinvoice' +
        '?shopName=' +
        this.storageData.VendorStoreName +
        '&shopAddress=' +
        this.storageData.VendorStoreAddress +
        '&customerName=' +
        this.storageData.UserMobileKey +
        '&printerWidth=65&shopCity=Indore&shopState=Madhya Pradesh'
    );
  }
}
