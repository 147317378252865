import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  PopoverController,
  ModalController,
  AlertController,
  IonSearchbar,
} from '@ionic/angular';
import { CategoryMenuPopoverComponent } from '../category-menu-popover/category-menu-popover.component';
import {
  ItemMenu,
  ItemMenuCategory,
  MealTimeCategory,
  StoreMenuInterface,
  StoreService,
} from './store.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { PopOverScrollSevice } from '../popoverScrollService';
import { environment } from 'src/environments/environment.prod';
import { CartInterface, CartService } from '../cart.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { Router } from '@angular/router';
import { UiService } from '../ui.service';
import { ItemCustomizationModalComponent } from '../shared/components/item-customization-modal/item-customization-modal.component';
import { RepeatLastModalPage } from '../shared/components/repeat-last-modal/repeat-last-modal.page';
import { Title } from '@angular/platform-browser';
import { BannerService } from '../core/services/banner.service';
import { BannerInterface } from '../core/model/banner.model';

@Component({
  selector: 'app-store',
  templateUrl: './store.page.html',
  styleUrls: ['./store.page.scss'],
})
export class StorePage implements OnInit, OnDestroy {
  subscriptionModalDismiss: Subscription;
  popover;
  @ViewChild('content', { static: false }) content;
  @ViewChild('searchStore') searchInput!: IonSearchbar;
  fetchCategoryObs: Observable<any>;
  fetchItemsObs: Observable<any>;
  items: ItemMenuCategory[] = [];
  mealTimeItems: MealTimeCategory[] = [];
  itemsAll: ItemMenu[] = [];
  itemSearch: ItemMenuCategory[] = [];
  prevCategory: number;
  categories: any = [];
  headerDisplay = true;
  baseUrl = environment.base_url;
  vendorStoreName: any;
  // isSearchActive = false;
  loadingView = true;
  cartCount;
  currentItemDetail;
  cartData = [];
  showSearchBar = false;
  slideOpts = {
    initialSlide: 0,
    speed: 250,
    autoplay: true,
  };
  storageData: StorageInterface;
  totalPrice = 0;
  switchHeader = false;
  // searchActive = false;
  isLoggedIn$: Subject<boolean> = new Subject<boolean>();
  headerBanner: BannerInterface[] = [];
  footerBanner: BannerInterface[] = [];

  constructor(
    public popoverController: PopoverController,
    private storeService: StoreService,
    private addToCartComponent: ModalController,
    private cartService: CartService,
    private storageService: StorageService,
    private router: Router,
    private alertController: AlertController,
    public modalController: ModalController,
    private _storageService: StorageService,
    private uiService: UiService,
    private _bannerService: BannerService // private titleService: Title
  ) {
    // this.subscriptionPopOverScroll = popOverScrollService
    //   .get()
    //   .subscribe((categoryData) => {
    //     this.popover.dismiss();
    //     this.categoryByScroll(categoryData);
    //   });
    // titleService.setTitle('Store Name');

    this.subscriptionModalDismiss = cartService
      .dismissModal()
      .subscribe((response) => {
        addToCartComponent.dismiss();
      });

    cartService.cartDataChanged.subscribe((data: CartInterface[]) => {
      this.cartData = data;
      this.updateCartCountBadge();
      this.syncUIWithCart();
      this.totalPrice = this.cartService.getCartTotalPrice(this.itemsAll);
    });

    cartService.setCartData();
    this.cartData = cartService.getFullCartData();
    this.cartCount = cartService.getCartLength();
  }

  ngOnInit() {}

  async setStorageData() {
    this._storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.fetchBanner();
      if (!this.storageData.VendorKey) {
        this.router.navigate(['/oops']);
        this.ngOnDestroy();
        return;
      }

      if (this.storageData.UserMobileKey === '9999999999') {
        console.log('isLoggedIn');

        this.isLoggedIn$.next(false);
      } else {
        this.isLoggedIn$.next(true);
      }

      this.vendorStoreName = this.storageData.VendorStoreName;
      this.fetchItems();
    });
  }

  updateCartCountBadge() {
    this.cartData = this.cartService.getFullCartData();
    this.cartCount = this.cartService.getCartLength();
    if (this.cartCount === 0) {
      this.cartCount = undefined;
    }
  }
  async fetchBanner() {
    this._bannerService
      .fetchBanner({ vendorId: this.storageData?.VendorKey })
      .subscribe({
        next: (res) => {
          this.headerBanner.push(
            ...res.data.filter((banner) => banner.location === 'HOME_HEADER')
          );
          this.footerBanner.push(
            ...res.data.filter((banner) => banner.location === 'HOME_FOOTER')
          );
        },
        error: (err) => {
          this.uiService.presentToast(err);
        },
      });
  }
  async presentCategoryPopover() {
    const modal = await this.modalController.create({
      component: CategoryMenuPopoverComponent,
      animated: true,
      cssClass: this.cartCount ? 'menu-pop shift-menu-pop' : 'menu-pop',
    });

    modal.onDidDismiss().then((res) => {
      this.categoryByScroll(res.data);
    });
    return await modal.present();
  }

  addItemToCart(data: ItemMenu): void {
    // If item has variants and/or add on avilalble, open the this.modalController, else directly add to the card
    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      this.openItemCustomizationModal(data);
    } else {
      this.cartService.setCartData();
      data.qty = 1;
      this.currentItemDetail = data;
      this.updateCartData(data.qty);
    }
  }

  async openItemCustomizationModal(data, isIllChoose = false) {
    const modal = await this.modalController.create({
      component: ItemCustomizationModalComponent,
      cssClass: 'item-customization-modal',
      componentProps: { data },
    });
    modal.onDidDismiss().then((res) => {
      if (res.data) {
        this.cartService.setCartData();

        if (isIllChoose) {
          data.qty += 1;
        } else {
          data.qty = 1; // this is for ui to change button
        }

        this.cartService.manageCustomizableItem(data, res);
      }
    });

    return await modal.present();
  }

  updateCartData(qty) {
    this.cartService.updateCartData({
      ItemIdPK: this.currentItemDetail.ItemIdPK,
      qty: qty,
      price: this.currentItemDetail.price,
      recommendedIds: this.currentItemDetail.recommendedIds,
      ...(this.currentItemDetail.InventoryItemMasterIdFK && {
        InventoryItemMasterIdFK: this.currentItemDetail.InventoryItemMasterIdFK,
      }),
    });
  }

  increaseItemQty(data) {
    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      this.openRepeatLastModal(data);
    } else {
      ++data.qty;
      this.currentItemDetail = data;
      this.updateCartData(data.qty);
    }
  }

  async openRepeatLastModal(data) {
    const modal = await this.modalController.create({
      component: RepeatLastModalPage,
      cssClass: 'repeat-customization-modal',
      componentProps: { data },
    });

    modal.onDidDismiss().then((res) => {
      if (res.data) {
        // repeat cart last item
        if (res.data.isRepeat) {
          this.cartService.repeatCartItem(data.ItemIdPK);
          // data.qty += 1;
        } else {
          // I'll choose
          this.openItemCustomizationModal(data, true);
        }
      }
    });
    return await modal.present();
  }

  decreaseItemQty(data) {
    if (
      (data.variantIds !== '' && data.variantIds !== null) ||
      data.isAddOnExists
    ) {
      // check for customization
      if (this.cartService.isItemRemovePossible(data.ItemIdPK)) {
        // remove then
        if (data.qty <= 1) {
          this.promotedItemRemovedFromCart(data.ItemIdPK);
        }
        this.cartService.decreaseQtyOfCustomizableItem(data.ItemIdPK);
        // --data.qty;
      } else {
        // navigate to cart
        this.uiService.presentActionSheet(
          'This item has multiple customizations added. Proceed cart to remove item?',
          [
            {
              text: 'Yes',
              handler: () => {
                this.router.navigateByUrl('/cart');
              },
            },
            {
              text: 'No',
              role: 'cancel',
            },
          ]
        );
      }
    } else {
      --data.qty;
      this.currentItemDetail = data;
      if (data.qty <= 0) {
        this.removeItemFromCart();
        this.promotedItemRemovedFromCart(data.ItemIdPK);
      } else {
        this.updateCartData(data.qty);
      }
    }
  }

  // this function will take care if same item removed from promoted or normal menu
  promotedItemRemovedFromCart(ItemIdPK) {
    this.items.forEach((item) => {
      item.items.forEach((menuItem) => {
        if (menuItem.ItemIdPK === +ItemIdPK) {
          menuItem.qty = 0;
        }
      });
    });

    if (this.mealTimeItems) {
      this.mealTimeItems.forEach((item) => {
        item.items.forEach((menuItem) => {
          if (menuItem.ItemIdPK === +ItemIdPK) {
            menuItem.qty = 0;
          }
        });
      });
    }
  }

  removeItemFromCart() {
    this.cartService.deleteCartData(this.currentItemDetail.ItemIdPK);
  }

  fetchItems() {
    this.fetchItemsObs = this.storeService.getMenu({
      vendorId: this.storageData.VendorKey,
    });

    this.fetchItemsObs.subscribe((res: StoreMenuInterface) => {
      this.items = res.categories;
      this.itemSearch = JSON.parse(JSON.stringify(this.items));

      this.mealTimeItems = res.mealTimes;

      if (this.cartData.length) {
        this.syncUIWithCart();
      }
      this.categories = this.storeService.getCategories();
    });
  }

  syncUIWithCart() {
    this.itemsAll = [];
    this.cartData.map((data) => {
      this.items.forEach((item) => {
        item.items.forEach((menuItem) => {
          if (data.ItemIdPK === menuItem.ItemIdPK) {
            menuItem.qty = data.qty;
          }
          this.itemsAll.push(menuItem);
        });
      });

      if (this.mealTimeItems) {
        this.mealTimeItems.forEach((mealTimeItem) => {
          mealTimeItem.items.forEach((menuItem) => {
            if (data.ItemIdPK === menuItem.ItemIdPK) {
              menuItem.qty = data.qty;
            }
          });
        });
      }
    });

    this.totalPrice = this.cartService.getCartTotalPrice(this.itemsAll);
  }

  categoryByScroll(categoryData) {
    let y = document.getElementById(categoryData.CategoryIdPK).offsetTop;
    this.content.scrollToPoint(0, y - 80, 1000);
  }

  ngOnDestroy() {
    this.subscriptionModalDismiss.unsubscribe();
  }
  onSearch(event) {
    if (!event.target.value || event.target.value === '') {
      // this.searchActive = false;
      this.showSearchBar = false;
      this.items = this.itemSearch;
    } else {
      let matches: ItemMenuCategory[] = JSON.parse(
        JSON.stringify(this.itemSearch)
      );
      this.items = [];

      matches.forEach((item) => {
        let removeIndexes = [];

        item.items.forEach((menuItem, i) => {
          if (
            !menuItem.name
              .toLowerCase()
              .includes(event.target.value.toLowerCase())
          ) {
            removeIndexes.push(i);
          }
        });

        if (removeIndexes.length) {
          let count = 0;
          removeIndexes.forEach((index) => {
            item.items.splice(index - count, 1);
            count++;
          });
        }
      });

      let i = matches.length;
      if (i >= 0) {
        while (i--) {
          if (matches[i].hasOwnProperty('items')) {
            if (matches[i].items.length === 0) {
              console.log(i, matches);
              matches.splice(i, 1);
              // i--;
            }
          }
        }
      }

      this.items = JSON.parse(JSON.stringify(matches));
      // this.searchActive = true;
      this.showSearchBar = true;
    }
    console.log('after-search', this.items);
  }

  resetItems() {
    this.items = this.itemSearch;
  }

  cancelSearch() {
    // this.searchActive = false;
    this.showSearchBar = false;
    this.resetItems();
  }

  ionViewWillEnter() {
    console.log('ion-view');

    this.updateCartCountBadge();
    this.setStorageData();
  }

  async onRescan() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'Are you sure you want to rescan qr code!!!',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Okay',
          handler: () => {
            this.storageService.clearCartFromStorage();
            this.router.navigateByUrl('/landing', { replaceUrl: true });
          },
        },
      ],
    });

    await alert.present();
  }

  // New UI Specific
  addToCart() {}

  toggleSearchBar(e) {
    this.showSearchBar = !this.showSearchBar;
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 150);
  }

  // async openScanner() {
  //   const modal = await this.modalController.create({
  //     component: ScannerComponent,
  //     cssClass: 'scanner-modal',
  //   });
  //   return await modal.present();
  // }

  openScanner() {}

  // get cartTotalPrice(): number {
  //   return 0;
  // }

  onImageLoadError(e: any) {
    e.target.src = environment.base_url + 'images/defaultitem.png';
  }

  // toggleCategoriesMore() {
  //   this.showMoreCategories = !this.showMoreCategories;
  // }

  getScrollPosition(e) {
    if (e.detail.scrollTop >= 120) {
      this.switchHeader = true;
    } else {
      this.switchHeader = false;
    }
  }
}
