import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class UserAddressesService {
  _http = inject(HttpClient);

  createAddress(body: any): Observable<any> {
    return this._http.post(
      environment.base_url + environment.create_address_api,
      body
    );
  }

  updateAddress(addressId: number, body: any): Observable<any> {
    return this._http.patch(
      environment.base_url +
        environment.update_address_api.replace('${id}', addressId.toString()),
      body
    );
  }

  fetchAddresses(): Observable<any> {
    return this._http
      .get(environment.base_url + environment.fetch_address_api)
      .pipe(
        map((res: any) => {
          if (res.data.length) {
            res.data[0].isSelected = true;
          }
          return res;
        })
      );
  }
}

export interface UserAddresses {
  AddressIdPK: number;
  CustomerIdFK: number;
  CityIdFK: number;
  area: string;
  location: string;
  landmark: string;
  latitude: number;
  longitude: number;
  isDefault: number;
  city: City;
  isSelected?: boolean;
}

export interface City {
  cityName: string;
}
