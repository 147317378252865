import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable()
export class ProfileService {
  constructor(private httpClient: HttpClient) {}

  updateCustomerProfile(obj, customerIdPK): Observable<any> {
    return this.httpClient.patch(
      environment.base_url + environment.update_profile_method,
      obj,
      {
        params: {
          CustomerIdPK: customerIdPK,
        },
      }
    );
  }

  deleteAccountReq(body: any): Observable<any> {
    return this.httpClient.post(
      environment.base_url + environment.delete_acc_req_api,
      body
    );
  }
}
