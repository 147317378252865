import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class PopOverScrollSevice {

    constructor() {}
    private currentCatgoryScroll = new Subject<any>();

    set(categoryData) {
        this.currentCatgoryScroll.next(categoryData);
    }

    get(): Observable<any> {
        return this.currentCatgoryScroll.asObservable();
    }
}
