import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import { ToastController } from '@ionic/angular';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ErrorHandler } from '../errorhandler.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {
  scanActive: boolean = false;
  drawerIsPulled = false;
  searchFormControl = new FormControl(null, Validators.required);
  vendorDetails: any;
  storageData: StorageInterface;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private errorHandler: ErrorHandler,
    private router: Router,
    private toastController: ToastController,
    private _uiService: UiService
  ) {}

  ngOnInit(): void {
    this.setStorageData();
    this.startScanner();
    this.loadSearchFilter();
  }

  async setStorageData() {
    this.storageData = await this.storageService.getObject(
      StorageEnum.UserData
    );
  }

  async checkPermission() {
    return new Promise(async (resolve, reject) => {
      try {
        const status = await BarcodeScanner.checkPermission({ force: true });
        if (status.granted) {
          resolve(true);
        } else if (status.denied) {
          BarcodeScanner.openAppSettings();
          resolve(false);
        }
      } catch (e) {
        resolve(false);
      }
    });
  }

  async startScanner() {
    try {
      const allowed = await this.checkPermission();
      if (allowed) {
        console.log('here');

        this.scanActive = true;
        BarcodeScanner.hideBackground();

        const result = await BarcodeScanner.startScan();

        if (result.hasContent) {
          this.scanActive = false;

          let data: {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          } = {} as {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          };
          //easeinn.app/menu/104DOTO

          const urlEndStoreCode = result.content.split('/')[2];

          data.vendorCode = urlEndStoreCode.split('D')[0];
          data.diningAreaId = urlEndStoreCode.split('D')[1].split('T')[0];
          data.tableNumber = urlEndStoreCode.split('D')[1].split('T')[1];

          this.goToUser(
            'EI' + data.vendorCode,
            data.tableNumber,
            data.diningAreaId
          );
        } else {
          alert('NO DATA FOUND!');
        }
      } else {
        // <input type="file" accept="image/*;capture=camera">
        this._uiService.presentAlert(
          'Looking to scan code 📸?',
          "Don't worry, you enter the store code manually below!",
          "Our scanner doesn't support web browsers for now. To use it, please install the EaseInn App from the Play Store. or use your camera app & point at the QR code!",
          'ios',
          [
            {
              text: 'Understood!',
            },
          ]
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  async goToUser(
    vendorCode,
    tableNumber,
    diningAreaId: string,
    isFetchDetailOnly?: boolean
  ) {
    this.storageData.VendorStoreKey = vendorCode;
    await this.storageService.setObject(StorageEnum.UserData, this.storageData);
    this.http
      .get(environment.base_url + environment.vendor_fetch, {
        params: {
          vendorCode,
          tableNumber,
          diningAreaId,
        },
      })
      .pipe(catchError(this.errorHandler.handleError))
      .subscribe(
        async (responseData: any) => {
          if (responseData.statusCode === 200) {
            this.storageData.VendorKey = responseData.data.VendorIdPK;
            this.storageData.VendorStatus = responseData.status;
            this.storageData.VendorStoreName =
              responseData.data.vendorStoreName;
            this.storageData.VendorStoreAddress =
              responseData.data.vendorLocation;
            this.storageData.CurrentTablePK = responseData.TableIdPK;
            this.storageData.CurrentTableNumber = tableNumber;
            this.storageData.DiningAreaId = diningAreaId;

            await this.storageService.setObject(
              StorageEnum.UserData,
              this.storageData
            );

            if (isFetchDetailOnly) {
              this.vendorDetails = responseData.data;
              this.drawerIsPulled = true;
            } else {
              this.router.navigate(['/store']);
            }
          } else {
          }
          console.log(responseData);
        },
        (error) => {
          console.log(error);
          // this.isContinueActive = false;
          // this.loadingMessage = false;
          this.vendorDetails = undefined;
          if (error === 'Error: Vendor does not exists') {
            // this.scannerError = false;
            this.presentToast('Invalid Code');
          } else {
            this.presentToast(error);
          }
        }
      );
  }

  async presentToast(message: string) {
    const toast = await this.toastController.create({
      message,
      duration: 2000,
    });
    toast.present();
  }

  stopScanner() {
    BarcodeScanner.stopScan();
    this.scanActive = false;
  }

  ionViewWillLeave() {
    BarcodeScanner.stopScan();
    this.scanActive = false;
  }

  onPullTrigger() {
    this.drawerIsPulled = !this.drawerIsPulled;
  }

  loadSearchFilter(): void {
    this.searchFormControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        tap((term: string) => {
          let data: {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          } = {} as {
            vendorCode: string;
            tableNumber: string;
            diningAreaId: string;
          };
          if (term.length < 3) {
            return;
          }
          data.vendorCode = term;
          data.diningAreaId = '0';
          data.tableNumber = '0';
          term = term + 'D0T0';

          // data.vendorCode = term.split('D')[0];
          // data.diningAreaId = term.split('D')[1].split('T')[0];
          // data.tableNumber = term.split('D')[1].split('T')[1];

          this.goToUser(
            'EI' + data.vendorCode,
            data.tableNumber,
            data.diningAreaId,
            true
          );
        })
      )
      .subscribe();
  }

  onSelectStore() {
    this.router.navigate(['/store']);
  }
}
