import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-internet',
  templateUrl: './no-internet.page.html',
  styleUrls: ['./no-internet.page.scss'],
})
export class NoInternetPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
