import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptorService } from './auth.interceptor.service';
import { CategoryMenuPopoverComponent } from './category-menu-popover/category-menu-popover.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderStatusPage } from './order/order-status/order-status.page';
import { OrderPreferenceModalComponent } from './order-preference-modal/order-preference-modal.component';
import { CartPage } from './cart/cart.page';
import { StorePage } from './store/store.page';
import { OrderPage } from './order/order.page';
import { OopsPage } from './oops/oops.page';
import { ActivePage } from './order/active/active.page';
import { PastPage } from './order/past/past.page';
import { OffersPage } from './offers/offers.page';
import { WelcomeScreenPage } from './welcome-screen/welcome-screen.page';
import { AuthPage } from './auth/auth.page';
import { AllStoresPage } from './all-stores/all-stores.page';
import { LandingPage } from './landing/landing.page';
import { NoInternetPage } from './no-internet/no-internet.page';
import { UserAccountPage } from './user-account/user-account.page';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ProfilePage } from './profile/profile.page';
import { NotificationsPage } from './notifications/notifications.page';
import { SharedModule } from './shared/shared.module';
import { ItemCustomizationModalComponent } from './shared/components/item-customization-modal/item-customization-modal.component';
import { RepeatLastModalPage } from './shared/components/repeat-last-modal/repeat-last-modal.page';
import { CategoryFragmentComponent } from './shared/components/category-fragment/category-fragment.component';
import { InvoicePage } from './invoice/invoice.page';
import { CouponsPage } from './coupons/coupons.page';
import { WebLoginPage } from './auth/web-login/web-login.page';
import { AskCustomerInfoPage } from './cart/ask-customer-info/ask-customer-info.page';
import { HomePage } from './home/home.page';
import { UserAddressesPage } from './user-addresses/user-addresses.page';
import { CreateAddressComponent } from './create-address/create-address.component';
import { DeleteAccountPage } from './delete-account/delete-account.page';
@NgModule({
  declarations: [
    AppComponent,
    CategoryMenuPopoverComponent,
    OrderPreferenceModalComponent,
    CartPage,
    StorePage,
    OrderPage,
    OopsPage,
    ActivePage,
    PastPage,
    OrderStatusPage,
    OffersPage,
    WelcomeScreenPage,
    AuthPage,
    AllStoresPage,
    LandingPage,
    NoInternetPage,
    UserAccountPage,
    ProfilePage,
    NotificationsPage,
    ItemCustomizationModalComponent,
    RepeatLastModalPage,
    CategoryFragmentComponent,
    InvoicePage,
    CouponsPage,
    WebLoginPage,
    AskCustomerInfoPage,
    HomePage,
    UserAddressesPage,
    CreateAddressComponent,
    DeleteAccountPage,
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({}),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
