import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcome-screen.page.html',
  styleUrls: ['./welcome-screen.page.scss'],
})
export class WelcomeScreenPage implements OnInit {
  test = window.location.href;
  constructor(private _router: Router) {}

  ngOnInit() {
    let url = 'https://hungerbangs.easeinn.app';

    console.log(url.split('.')[0].split('//'));
    // console.log(window.location.href.split('.'));
  }
}
