import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { Observable, Subject } from 'rxjs';
import { StorageService } from '../storage.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class StoreService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {}

  categories: any = [];
  categoryChanged = new Subject<any>();

  getCategory() {
    return this.http.get(environment.base_url + environment.category_fetch, {
      params: {
        currentPage: '',
        itemsPerPage: '',
        searchData: '',
      },
    });
  }

  getItems(vendorKey) {
    return this.http.get(environment.base_url + environment.item_fetch, {
      params: {
        currentPage: '',
        itemsPerPage: '',
        searchData: '',
        VendorIdPK: vendorKey,
      },
    });
  }

  setCategories(categoryData) {
    this.categories = categoryData;
    this.categoryChanged.next(this.categories.slice());
  }

  getCategories() {
    return [...this.categories];
  }

  getMenu(params: any): Observable<StoreMenuInterface> {
    return this.http
      .get(environment.base_url + environment.fetch_menu_api, {
        params,
      })
      .pipe(
        map((res: { data: StoreMenuInterface }) => {
          console.log('res', res.data);

          this.categories = [];
          res.data.categories.forEach((menu) => {
            this.categories.push({
              CategoryIdPK: menu.items[0].CategoryIdFK,
              image: menu.items[0].category.image,
              name: menu.name,
              itemLength: menu.items.length,
            });
            menu.items.forEach((item) => {
              if (
                (item.variantIds !== '' && item.variantIds !== null) ||
                item.isAddOnExists
              ) {
                item.isCustomizable = true;
              }
            });
          });

          this.setCategories(this.categories);

          return res.data;
        })
      );
  }

  fetchItemByIds(params: any): Observable<any> {
    return this.http
      .get(environment.base_url + environment.fetch_item_by_ids_api, { params })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  fetchAddOns(params: any): Observable<any> {
    return this.http
      .get(environment.base_url + environment.fetch_add_ons, {
        params,
      })
      .pipe(
        map((res: any) => {
          return res.data;
        })
      );
  }

  fetchVendorMetaTags(): Observable<any> {
    return this.http.get(
      environment.base_url + environment.fetch_store_meta_tags,
      {
        headers: {
          'ngrok-skip-browser-warning': 'true',
        },
      }
    );
  }
}
export interface StoreMenuInterface {
  categories: ItemMenuCategory[];
  mealTimes: MealTimeCategory[];
}
export interface ItemMenuCategory {
  name: string;
  items: ItemMenu[];
}
export interface MealTimeCategory {
  name: string;
  items: ItemMenu[];
}
export interface ItemMenu {
  ItemIdPK: number;
  CategoryIdFK: number;
  VendorIdFK: number;
  isAddOnExists: boolean;
  comboIds: string;
  recommendedIds: string;
  mealTimeIds: string;
  variantIds: string;
  name: string;
  image: string;
  price: number;
  gstPercent: number;
  type: string;
  shortDesc: string;
  status: string;
  isVariant: boolean;
  prepTime: number;
  category: Category;
  tags: Tag[];
  isCustomizable?: boolean;
  qty?: number;
  offers?: any;
}

export interface Category {
  CategoryIdPK: number;
  name: string;
  VendorIdFK: number;
  image?: any;
  sortOrder: number;
}

export interface Tag {
  ItemTagsIdPK: number;
  name: string;
}
