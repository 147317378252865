import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { CartService } from '../cart.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.page.html',
  styleUrls: ['./coupons.page.scss'],
})
export class CouponsPage implements OnInit {
  @Input() vendorId;
  @Input() cartValue: number;
  fetchCoupons$: Observable<any>;
  couponFormControl = new FormControl(null, [Validators.required]);
  allCoupons: CouponInterface[] = [];
  listedCoupons: CouponInterface[] = [];
  constructor(
    private _cartService: CartService,
    private _modalCntrl: ModalController
  ) {}

  ngOnInit() {
    console.log(this.cartValue);

    this.fetchCoupons();
  }

  fetchCoupons() {
    this.fetchCoupons$ = this._cartService.fetchCoponsForCustomer(
      this.vendorId
    );

    this.fetchCoupons$.subscribe((res) => {
      console.log('coupons', res);
      this.allCoupons = res;
      this.allCoupons.forEach((coupon) => {
        if (
          coupon.appVisibility &&
          this.cartValue >= coupon.cartMinValue &&
          this.cartValue <= coupon.cartMaxValue
        ) {
          this.listedCoupons.push(coupon);
        }
      });
    });
  }

  backToCart() {
    this._modalCntrl.dismiss();
  }

  onApplyCoupon(coupon: CouponInterface) {
    this._modalCntrl.dismiss({
      coupon,
    });
  }
}

export interface CouponInterface {
  CouponIdPK: number;
  VendorIdFK: number;
  code: string;
  discount: number;
  discountType: string;
  appVisibility: boolean;
  expiryDate: string;
  status: string;
  createdAt: string;
  cartMinValue: number;
  cartMaxValue: number;
  maximumAllowedDiscount: number;
}
