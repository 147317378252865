import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { USER_STATUS } from './app.enum';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from './storage.service';

@Injectable({ providedIn: 'root' })
export class SessionGuard implements CanActivate {
  constructor(private router: Router, private storageService: StorageService) {}

  async canActivate() {
    console.log('session-guard');

    this.storageService
      .getObject(StorageEnum.UserData)
      .then((data: StorageInterface) => {
        console.log('session-guard', data);

        if (!data) {
          return true;
        }
        if (data.VendorKey) {
          this.router.navigateByUrl('/store', { replaceUrl: true });
          return false;
        } else if (
          data.CustomerKey &&
          data.UserStatusKey === USER_STATUS.VERIFIED
        ) {
          this.router.navigateByUrl('/landing', { replaceUrl: true });
          return false;
        }
      });
    return true;
  }
}
