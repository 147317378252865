import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-category-fragment',
  templateUrl: './category-fragment.component.html',
  styleUrls: ['./category-fragment.component.scss'],
})
export class CategoryFragmentComponent implements OnInit {
  showMoreCategories = false;
  @Output() scrollToEmitter$ = new EventEmitter();
  baseUrl = environment.base_url;
  constructor() {}

  @Input() categories: any = [];

  ngOnInit() {}

  scrollTocategory(categoryData) {
    console.log(categoryData);
    this.scrollToEmitter$.emit(categoryData);
  }

  toggleCategoriesMore() {
    this.showMoreCategories = !this.showMoreCategories;
  }

  get getOnlyFourCategories() {
    console.log('four');

    return this.categories.length > 8 && this.showMoreCategories
      ? this.categories.slice(0, 8)
      : this.categories;
  }

  onImageLoadError(e: any) {
    e.target.src = '../../assets/icons/menu.png';
  }
}
