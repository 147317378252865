import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Platform, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Title } from '@angular/platform-browser';
import { App as CapacitorApp } from '@capacitor/app';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { StorageService } from './storage.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UiService } from './ui.service';
import { Capacitor } from '@capacitor/core';
// import { title } from 'process';
// import { async } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'EaseInn | The Next Level Dining';
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private storageService: StorageService,
    private alertController: AlertController,
    private uiService: UiService,
    private titleService: Title,
    private _activatedRoute: ActivatedRoute
  ) {
    this.initializeApp();

    // titleService.setTitle('Store Name');

    // console.log('Router Data: ', _activatedRoute);
  }
  message;
  status;
  audio;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;
  alertRef: HTMLIonAlertElement;

  ngOnInit() {
    // let favIcon = document.querySelector('#appIcon');
    // favIcon.setAttribute('href', 'https://www.google.com/favicon.ico');
  }

  // setDocTitle(title: string) {
  //   console.log('current title:::::' + this.titleService.getTitle());
  //   this.titleService.setTitle(title);
  // }

  playAudio() {
    this.audio = new Audio();
    this.audio.src = 'assets/audio/ascent.mp3';
    this.audio.load();
    // this.audio.loop = true;
    this.audio.play();
  }

  ngAfterViewInit() {
    // this.platform.ready().then(async () => {
    //    await this.notificationsService.requestPermission();
    // });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();

      if (Capacitor.isPluginAvailable('PushNotifications')) {
        this.initPushNotification();
      }

      // CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      this.platform.backButton.subscribeWithPriority(-1, () => {
        switch (this.router.url) {
          case '/store':
          case '/welcome-screen':
          case '/auth':
            if (
              new Date().getTime() - this.lastTimeBackPress <
              this.timePeriodToExit
            ) {
              CapacitorApp.exitApp();
            } else {
              this.uiService.presentToast('Press back again to exit App');
            }
            this.lastTimeBackPress = new Date().getTime();
            break;
        }
      });
    });
  }

  initPushNotification() {
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);
      this.storageService.setFcmToken(token.value);
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        console.log(notification);

        this.playAudio();

        if (this.alertRef) {
          this.alertRef.dismiss();
        }

        this.storageService.orderStatusUpdated.next(true);
        // Order Accepted
        if (notification.data.status === 'ACCEPTED') {
          this.presentAlert(
            'Your order has been accepted. You can check live status by tracking your order or by contacting vendor at counter.'
          );
        }

        if (notification.data.status === 'REJECTED') {
          this.presentAlert(
            'Your order has been rejected. You can check live status by tracking your order or by contacting vendor at counter.'
          );
        }

        if (notification.data.status === 'COMPLETED') {
          this.presentAlert(
            'Your order has been completed. Thank you for using EASEINN!'
          );
        }
      }
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  statusCheck() {
    this.stopAudio();
    // to hide fab button on click
    this.status = undefined;
  }

  stopAudio() {
    this.audio.pause();
  }

  async presentAlert(message) {
    this.alertRef = await this.alertController.create({
      header: 'Alert',
      subHeader: 'Order info',
      message,
      buttons: ['OK'],
    });
    await this.alertRef.present();
  }
}
