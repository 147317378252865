import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { FieldErrorsComponent } from './components/field-errors/field-errors.component';

@NgModule({
  declarations: [FooterComponent, FieldErrorsComponent],
  imports: [CommonModule, IonicModule],
  exports: [FooterComponent, FieldErrorsComponent],
})
export class SharedModule {}
