import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { UiService } from '../ui.service';

@Component({
  selector: 'app-order-preference-modal',
  templateUrl: './order-preference-modal.component.html',
  styleUrls: ['./order-preference-modal.component.scss'],
})
export class OrderPreferenceModalComponent implements OnInit {
  @Input() min;
  @Input() max;
  time: any;
  minTimeCheck = '';
  maxTimeCheck = '';
  errorMessage = '';
  orderTypeFormControl = new FormControl('Schedule');
  openDateTime = false;
  constructor(
    private modalCtrl: ModalController,
    private uiService: UiService
  ) {}

  ngOnInit() {
    console.log('modal', this.min, this.max);
    this.prepareData();
  }

  dismiss() {
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  prepareData() {
    if (this.min.hour < 10) {
      this.minTimeCheck = '0' + this.min.hour;
    } else {
      this.minTimeCheck = '' + this.min.hour;
    }

    if (this.min.minute < 10) {
      this.minTimeCheck = this.minTimeCheck + ':0' + this.min.minute;
    } else {
      this.minTimeCheck = this.minTimeCheck + ':' + this.min.minute;
    }

    if (this.max.hour < 10) {
      this.maxTimeCheck = '0' + this.max.hour;
    } else {
      this.maxTimeCheck = '' + this.max.hour;
    }

    if (this.max.maxute < 10) {
      this.maxTimeCheck = this.maxTimeCheck + ':0' + this.max.maxute;
    } else {
      this.maxTimeCheck = this.maxTimeCheck + ':' + this.max.minute;
    }
    console.log(this.minTimeCheck, this.maxTimeCheck);
  }

  onChangeTime(e) {
    console.log(e);
  }

  onConfirm() {
    if (this.orderTypeFormControl.value === 'Instant') {
      this.modalCtrl.dismiss({
        orderType: 'Instant',
      });
      return;
    }
    this.errorMessage = '';

    // check if more than current time

    if (
      +this.time.split('T')[1].split(':')[0] <
      +new Date().toLocaleString().split(',')[1].split(':')[0]
    ) {
      this.errorMessage = 'Selcted time is less than current time.';
    } else if (
      +this.time.split('T')[1].split(':')[0] ===
      +new Date().toLocaleString().split(',')[1].split(':')[0]
    ) {
      if (
        +this.time.split('T')[1].split(':')[1] <
        +new Date().toLocaleString().split(',')[1].split(':')[1]
      ) {
        this.errorMessage = 'Selcted time is less than current time.';
      }
    }

    if (+this.time.split('T')[1].split(':')[0] > +this.max.hour) {
      this.errorMessage = 'At selected time vendor does not accept orders.';
    } else if (+this.time.split('T')[1].split(':')[0] === +this.max.hour) {
      if (+this.time.split('T')[1].split(':')[1] > +this.max.minute) {
        this.errorMessage = 'At selected time vendor does not accept orders.';
      }
    }

    if (+this.time.split('T')[1].split(':')[0] < +this.min.hour) {
      this.errorMessage = 'At selected time vendor does not accept orders.';
    } else if (+this.time.split('T')[1].split(':')[0] === +this.min.hour) {
      if (+this.time.split('T')[1].split(':')[1] < +this.min.minute) {
        this.errorMessage = 'At selected time vendor does not accept orders.';
      }
    }

    if (this.errorMessage !== '') {
      this.uiService.presentToast(this.errorMessage);
      return;
    }

    this.modalCtrl.dismiss({
      orderType: 'Schedule',
      scheduleTime: this.time,
    });
    console.log(this.time);
  }

  onSelectTime($event) {
    console.log($event);
  }
}
