import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CartService } from '../cart.service';
import { AlertController } from '@ionic/angular';
import { OrderService } from './order.service';
import { Observable, of, Subject } from 'rxjs';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.page.html',
  styleUrls: ['./order.page.scss'],
})
export class OrderPage implements OnInit {
  activeTab = 'ACTIVE';
  viewEnterListen = new Subject<any>();

  fetchOrderObs: Observable<any>;
  showLoader = true;
  orders: any = [];
  emptyOrderStatus = false;
  storageData: StorageInterface;
  orders$: Observable<any[]>;

  constructor(
    private cartService: CartService,
    private alertController: AlertController,
    private storageService: StorageService,
    private orderService: OrderService,
    private changeDetector: ChangeDetectorRef
  ) {
    cartService.setCartData();
    this.cartCount = cartService.getFullCartData().length;
    storageService.orderStatusUpdated.subscribe((res) => {
      if (res) {
        this.fetchOrders();
      }
    });
  }

  cartCount;
  segmentValue = 'ORDER';

  ngOnInit() {}

  async setStorageData() {
    this.storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.fetchOrders();
    });
  }

  ionViewWillEnter() {
    console.log('main tab');
    this.segmentValue = 'ORDER';
    this.updateCartCountBadge();
    this.setStorageData();
  }

  updateCartCountBadge() {
    this.cartCount = this.cartService.getFullCartData().length;
    if (this.cartCount === 0) {
      this.cartCount = undefined;
    }
  }

  async presentAlert(message) {
    const alert = await this.alertController.create({
      header: 'Order info',
      subHeader: '',
      message,
      buttons: ['OK'],
      mode: 'ios',
    });
    await alert.present();
  }

  toggle(tab) {
    this.activeTab = tab;
  }

  fetchOrders(event?) {
    this.fetchOrderObs = this.orderService.fetchOrders(
      {
        status: 'ACTIVE',
      },
      this.storageData.CustomerKey
    );

    this.fetchOrderObs.subscribe(
      (responseData) => {
        this.orders = [];
        this.showLoader = false;
        if (responseData.data.length === 0) {
          this.emptyOrderStatus = true;
        }
        this.orders = [...responseData.data];
        this.orders$ = of(this.orders);
        this.changeDetector.detectChanges();
        console.log('orders', this.orders, this.emptyOrderStatus);
      },
      (err) => {
        console.log(err);
      },
      () => {
        if (event) {
          event.target.complete();
        }
      }
    );
  }

  doRefresh(event) {
    this.fetchOrders(event);
  }
}
