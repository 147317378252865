import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { StorageService } from '../storage.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandler } from '../errorhandler.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private errorHandler: ErrorHandler
  ) {}

  validateCustomer(
    data: string,
    fcmToken?: string,
    name?: string
  ): Observable<any> {
    return this.http.post(
      environment.base_url + environment.mobile_validate,
      { mobile: data, ...(fcmToken && { fcmToken }), ...(name && { name }) },
      {
        observe: 'response',
      }
    );
  }

  updateCustomer(data, customerKey) {
    return this.http.patch(
      environment.base_url +
        environment.edit_customer +
        '?CustomerIdPK=' +
        customerKey,
      data
    );
  }

  sendOtp(mobile) {
    return this.http
      .post(
        environment.base_url + environment.otp_send,
        {
          mobile,
        },
        {
          observe: 'response',
        }
      )
      .pipe(catchError(this.errorHandler.handleError));
  }

  updateUserStatus(status: string, customerKey) {
    return this.http.patch(
      environment.base_url +
        environment.edit_customer +
        '?CustomerIdPK=' +
        customerKey,
      { status }
    );
  }
}
