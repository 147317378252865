import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { StoreService } from '../store/store.service';
import { PopOverScrollSevice } from '../popoverScrollService';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-category-menu-popover',
  templateUrl: './category-menu-popover.component.html',
  styleUrls: ['./category-menu-popover.component.scss'],
})
export class CategoryMenuPopoverComponent implements OnInit {
  // @Output() scrollToEmitter$ = new EventEmitter();

  constructor(
    private storeService: StoreService,
    private modalController: ModalController,
    private popScrollService: PopOverScrollSevice
  ) {}

  categories: any = [];

  ngOnInit() {}

  ionViewWillEnter() {
    this.categories = this.storeService.getCategories();
  }

  scrollTocategory(categoryData) {
    console.log(categoryData);
    this.modalController.dismiss(categoryData);
  }

  dismissPopover(e?) {
    this.modalController.dismiss();
  }
}
