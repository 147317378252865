import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { PopoverController, ModalController } from '@ionic/angular';
import { StoreService } from '../store/store.service';
import { PopOverScrollSevice } from '../popoverScrollService';
import { CartService } from '../cart.service';
import {
  StorageEnum,
  StorageInterface,
  StorageService,
} from '../storage.service';
import { CategoryMenuPopoverComponent } from '../category-menu-popover/category-menu-popover.component';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.page.html',
  styleUrls: ['./offers.page.scss'],
})
export class OffersPage implements OnInit {
  subscriptionPopOverScroll: Subscription;
  subscriptionModalDismiss: Subscription;
  popover;
  @ViewChild('content', { static: false }) content;
  fetchCategoryObs: Observable<any>;
  fetchItemsObs: Observable<any>;
  items: any = [];
  itemsAll: any = [];
  prevCategory: number;
  categories: any = [];
  headerDisplay = true;
  baseUrl = environment.base_url;
  vendorStoreName: any;
  isSearchActive = false;
  segmentValue = 'OFFERS';
  cartCount;
  storageData: StorageInterface;

  constructor(
    public popoverController: PopoverController,
    private storeService: StoreService,
    private popOverScrollService: PopOverScrollSevice,
    private cartService: CartService,
    private storageService: StorageService
  ) {
    this.subscriptionPopOverScroll = popOverScrollService
      .get()
      .subscribe((categoryData) => {
        this.popover.dismiss();
        console.log(categoryData);
        this.categoryByScroll(categoryData);
      });

    cartService.cartDataChanged.subscribe((data) => {
      console.log(data);
    });

    cartService.setCartData();
    this.cartCount = cartService.getFullCartData().length;
  }

  async presentCategoryPopover(ev: any) {
    this.popover = await this.popoverController.create({
      component: CategoryMenuPopoverComponent,
      translucent: true,
      animated: true,
    });
    ev = {
      target: {
        getBoundingClientRect: () => {
          return {
            bottom: 0,
          };
        },
      },
    };
    return await this.popover.present(ev);
  }

  ngOnInit() {
    this.setStorageData();
  }

  async setStorageData() {
    this.storageService.getObject(StorageEnum.UserData).then((res) => {
      this.storageData = res;
      this.vendorStoreName = this.storageData.VendorStoreName;
      this.fetchItems();
    });
  }

  fetchItems() {
    this.fetchItemsObs = this.storeService.getItems(this.storageData.VendorKey);
    this.fetchItemsObs.subscribe(
      (responseData) => {
        // console.log(responseData);
        if (responseData.statusCode === 200) {
          let tempItems = [];
          let i = 0;
          let k = 0;
          this.prevCategory = responseData.data[0].CategoryIdFK;
          responseData.data.forEach((element) => {
            console.log(element.offers.length);
            this.prevCategory = element.CategoryIdFK;
            if (element.offers.length !== 0) {
              if (this.prevCategory === element.CategoryIdFK) {
                tempItems[i] = element;
                i++;
              } else {
                console.log('here');
                this.prevCategory = element.CategoryIdFK;
                this.items[k] = tempItems;
                this.categories[k] = tempItems[0].category;
                k++;
                i = 0;
                tempItems = [];
                tempItems[i] = element;
                i++;
              }
            }
          });
          console.log(this.items);
          if (this.items.length !== 0 || tempItems.length !== 0) {
            this.items[k] = tempItems;
            this.categories[k] = tempItems[0].category;
            this.itemsAll = this.items;
            this.storeService.setCategories(this.categories);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  categoryByScroll(categoryData) {
    let y = document.getElementById(categoryData.CategoryIdPK).offsetTop;
    this.content.scrollToPoint(0, y, 1000);
  }

  ngOnDestroy() {
    this.subscriptionPopOverScroll.unsubscribe();
    this.subscriptionModalDismiss.unsubscribe();
  }

  onSearch(event) {
    // console.log(event.target.value);
    if (!event.target.value || event.target.value === '') {
      this.items = this.itemsAll;
    } else {
      let matches = [],
        i = 0,
        j = 0;
      this.items = [];
      this.itemsAll.forEach((element) => {
        // j = 0;
        i = 0;
        matches = [];
        element.forEach((elementNested) => {
          if (elementNested.name.match(event.target.value)) {
            matches[i] = elementNested;
            i++;
          }
        });
        console.log(matches);
        if (matches.length > 0) {
          this.items[j] = matches;
          j++;
        }
      });
    }
  }

  resetItems() {
    this.items = this.itemsAll;
  }

  ionViewWillEnter() {
    this.segmentValue = 'OFFERS';
    this.updateCartCountBadge();
  }

  updateCartCountBadge() {
    this.cartCount = this.cartService.getFullCartData().length;
    if (this.cartCount === 0) {
      this.cartCount = undefined;
    }
  }
}
